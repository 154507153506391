<template>
  <div class="tab-pane-inner">
    <div class="inspections-list form-inspection">
      <div class="inspection-title">
        <span>
          {{ $objectDenomination(customer) }} -
          {{ $t("COMMON.ESTIMATES") }}
        </span>
      </div>
      <estimate-list-table
        :filterCustomer="customer.id"
        @onAddEstimate="addEstimate"
        @onViewEstimate="viewEstimate"
        @onEditEstimate="editEstimate"
        @onDeleteEstimate="viewEstimate"
      />
    </div>
  </div>
</template>

<script>
import { QUERY_ACTIONS_ADD, QUERY_ACTIONS_EDIT } from "@/constants/common";
import EstimateListTable from "../../../InspectproModule/EstimateManagement/partials/EstimateListTable.vue";

export default {
  name: "customer-view-estimates",

  components: { EstimateListTable: EstimateListTable },

  props: ["customer"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    viewEstimate(estimate) {
      this.$router.push(
        this.$objectViewRoute({ id: estimate.id, type: "estimations" })
      );
    },
    editEstimate(estimate) {
      this.$router.push({
        name: "List Estimates",
        query: { id: estimate.id, action: QUERY_ACTIONS_EDIT },
      });
    },
    addEstimate() {
      this.$router.push({
        name: "List Estimates",
        query: {
          action: QUERY_ACTIONS_ADD,
          customer_id: this.customer.id,
        },
      });
    },
  },

  mounted() {},

  watch: {
    customer(customer) {},
  },
};
</script>
