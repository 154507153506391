<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ `${customer.customer_name}` }}</h3>
    <div class="col-12">
      <dl class="row" v-if="false">
        <dt class="col-sm-4">
          {{ $t("COMMON.TAGS") }}
        </dt>
        <dd class="col-sm-8">
          <tags :tags="customer.tags" @tagsUpdated="customerUpdated" />
        </dd>
      </dl>

      <dl class="row" v-if="customer.customer_type == CUSTOMER_TYPE_INDIVIDUAL">
        <dt class="col-sm-4">{{ $t("COMMON.FIRSTNAME") }}</dt>
        <dd class="col-sm-8">
          {{ customer.firstname }}
        </dd>
      </dl>

      <dl class="row" v-if="customer.customer_type == CUSTOMER_TYPE_INDIVIDUAL">
        <dt class="col-sm-4">{{ $t("COMMON.LASTNAME") }}</dt>
        <dd class="col-sm-8">
          {{ customer.lastname }}
        </dd>
      </dl>

      <dl class="row" v-if="customer.customer_type == CUSTOMER_TYPE_COMPANY">
        <dt class="col-sm-4">{{ $t("COMMON.COMPANY_NAME") }}</dt>
        <dd class="col-sm-8">
          {{ customer.company_name }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.EMAIL") }}</dt>
        <dd class="col-sm-8">
          {{ customer.email }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.PHONE") }}</dt>
        <dd class="col-sm-8">
          {{ customer.phone }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.COUNTRY") }}</dt>
        <dd class="col-sm-8">
          {{ customer.country }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.STATE") }}</dt>
        <dd class="col-sm-8">
          {{ customer.state }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CITY") }}</dt>
        <dd class="col-sm-8">
          {{ customer.city }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.ZIPCODE") }}</dt>
        <dd class="col-sm-8">
          {{ customer.zipcode }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.ADDRESS") }}</dt>
        <dd class="col-sm-8">
          {{ customer.address }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.LOCATIONS") }}</dt>
        <dd class="col-sm-8">
          <locations :locations="customer.allowedLocations" />
        </dd>
      </dl>

      <dl
        class="row"
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)"
      >
        <dt class="col-sm-4">{{ $t("COMMON.ORGANIZATION") }}</dt>
        <dd class="col-sm-8">
          <organization :organization="customer.organization" />
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ $timeZoneDateFormat(customer.created_at) }}
        </dd>
      </dl>

      <dl class="row">
        <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
        <dd class="col-sm-8">
          {{ $timeZoneDateFormat(customer.updated_at) }}
        </dd>
      </dl>

      <div class="col-12 mb-3 mt-4">
        <h2>{{ $t("COMMON.BILLING_ADDRESS") }}</h2>
      </div>

      <billing-informations-view
        :billingInformations="customer"
        :type="customer.customer_type"
      />
    </div>
  </div>
</template>

<script>
import {
  CUSTOMER_TYPE_INDIVIDUAL,
  CUSTOMER_TYPE_COMPANY,
} from "@/constants/customers";
import BillingInformationsView from "@/components/BillingInformationsView.vue";
import Tags from "@/components/Tags.vue";

export default {
  name: "customer-view-global",

  components: { Tags, BillingInformationsView },

  props: ["customer"],

  data() {
    return {
      CUSTOMER_TYPE_COMPANY: CUSTOMER_TYPE_COMPANY,
      CUSTOMER_TYPE_INDIVIDUAL: CUSTOMER_TYPE_INDIVIDUAL,
    };
  },

  computed: {},

  created() {},

  methods: {
    customerUpdated() {
      this.$emit("customerUpdated", true);
    },
  },

  mounted() {},

  watch: {
    customer(customer) {},
  },
};
</script>
