<template>
  <div class="container-fluid">
    <div class="page-title">
      <i class="fa-light fa-users"></i>
      <h1>{{ $t("COMMON.CUSTOMERS") }}</h1>
    </div>
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("CUSTOMERS.CUSTOMERS_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openCustomerCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_CUSTOMERS)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">
                {{ $t("CUSTOMERS.ADD_CUSTOMER") }}
              </span>
            </base-button>
            <!--<notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_CUSTOMERS)"
              :objectType="'customers'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />-->
          </div>
        </div>
      </div>

      <customer-list-table
        @onViewCustomer="openCustomerViewModal"
        @onEditCustomer="openCustomerEditModal"
        @onDeleteCustomer="deleteCustomer"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewCustomerModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewCustomerModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'CUSTOMER'"
          @onCloseModal="closeCustomerModal(true, true)"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CUSTOMERS.VIEW_CUSTOMER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openCustomer"
                :objectType="'customers'"
                :objectId="openCustomer.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button
                  class="edit"
                  @click="openCustomerEditModal(openCustomer)"
                >
                  <i class="fal fa-edit"></i>
                  {{ $t("COMMON.UPDATE") }}
                </button>
                <button class="delete" @click="deleteCustomer(openCustomer)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeCustomerModal(true)">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-customer-page
              v-if="openCustomer"
              :customerId="openCustomer.id"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isEditCustomerModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditCustomerModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'CUSTOMER'"
          @onCloseModal="closeCustomerModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CUSTOMERS.EDIT_CUSTOMER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button
                class="close"
                @click="openCustomerViewModal(openCustomer)"
              >
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-customer-page
              v-if="openCustomer"
              :customerId="openCustomer.id"
              @onViewCustomer="openCustomerViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddCustomerModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddCustomerModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div
          class="resizable-wrapper-content"
          v-resizable-modal="'CUSTOMER'"
          @onCloseModal="closeCustomerModal"
        >
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("CUSTOMERS.ADD_CUSTOMER") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeCustomerModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-customer-page @onViewCustomer="openCustomerViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import CustomerListTable from "./partials/CustomerListTable.vue";
import EditCustomerPage from "./components/EditCustomerComponent.vue";
import AddCustomerPage from "./components/AddCustomerComponent.vue";
import ViewCustomerPage from "./components/ViewCustomerComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    CustomerListTable,
    NotificationSubscription,
    EditCustomerPage,
    AddCustomerPage,
    ViewCustomerPage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const customerId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewCustomerModalOpened = false;
    let isEditCustomerModalOpened = false;
    let isAddCustomerModalOpened = false;
    let openCustomer = null;
    if (customerId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewCustomerModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditCustomerModalOpened = true;
      }
      openCustomer = { id: customerId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddCustomerModalOpened = true;
    }
    return {
      isViewCustomerModalOpened: isViewCustomerModalOpened,
      isEditCustomerModalOpened: isEditCustomerModalOpened,
      isAddCustomerModalOpened: isAddCustomerModalOpened,
      openCustomer: openCustomer,
      renderKey: 1,
    };
  },

  methods: {
    openCustomerCreateModal() {
      this.closeCustomerModal();
      this.isAddCustomerModalOpened = true;

      this.$router.replace({
        name: "List Customers",
        query: { action: QUERY_ACTIONS_ADD },
      });
    },
    openCustomerViewModal(customer, reRender = false) {
      this.closeCustomerModal();
      this.openCustomer = customer;
      this.isViewCustomerModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      this.$router.replace({
        name: "List Customers",
        query: { id: this.openCustomer.id, action: QUERY_ACTIONS_VIEW },
      });
    },
    openCustomerEditModal(customer) {
      this.closeCustomerModal();
      this.openCustomer = customer;
      this.isEditCustomerModalOpened = true;

      this.$router.replace({
        name: "List Customers",
        query: { id: this.openCustomer.id, action: QUERY_ACTIONS_EDIT },
      });
    },
    closeCustomerModal(goBack = false, grid = false) {
      if (goBack && this.$store.getters["routes/previousPathRoute"]) {
        if (grid) {
          this.$store.dispatch("routes/goBackToPreviousGrid");
        } else {
          this.$store.dispatch("routes/goToPreviousPath");
        }
        return;
      }
      this.isAddCustomerModalOpened = false;
      this.isViewCustomerModalOpened = false;
      this.isEditCustomerModalOpened = false;
      this.openCustomer = null;

      if (
        this.$route.name !== "List Customers" ||
        JSON.stringify(this.$route.query) !== JSON.stringify({})
      ) {
        this.$router
          .replace({
            name: "List Customers",
            query: {},
          })
          .catch((err) => {
            if (err.name !== "NavigationDuplicated") {
              throw err;
            }
          });
      }
    },

    async deleteCustomer(customer) {
      const confirmation = await swal.fire({
        title: this.$t("CUSTOMERS.DELETE_THIS_CUSTOMER"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.isConfirmed) {
          await this.$store.dispatch("customers/destroy", customer.id);
          this.renderKey++;
          this.closeCustomerModal();
          this.$notify({
            type: "success",
            timeout: 3000,
            message: this.$t("CUSTOMERS.CUSTOMER_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
