<template>
  <div class="container-fluid">
    <customer-form
      :loading="loading"
      :customerData="customer"
      :formErrors="formErrors"
      @customerSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      :billing-address-same-as-customer-value="true"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultCustomer from "../defaultCustomer";
import CustomerForm from "../partials/CustomerForm.vue";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    CustomerForm,
  },

  mixins: [alertLeave],

  data() {
    return {
      customer: cloneDeep(defaultCustomer),
      formErrors: null,
      loading: false,
    };
  },

  created() {},

  methods: {
    async handleSubmit(customer) {
      this.loading = true;

      const customerData = cloneDeep(customer);
      delete customerData.id;

      try {
        await this.$store.dispatch("customers/add", customerData);
        this.$notify({
          type: "success",
          timeout: 3000,
          message: this.$t("CUSTOMERS.CUSTOMER_ADDED"),
        });
        const customer = await this.$store.getters["customers/customer"];
        this.$emit("onViewCustomer", customer, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
